// extracted by mini-css-extract-plugin
export var artworkImg = "pastwisko-module--artwork-img---9Zzt";
export var bodyCol = "pastwisko-module--body-col--7XpPS";
export var copyright = "pastwisko-module--copyright--UfSE1";
export var credits = "pastwisko-module--credits--0fqNU";
export var hl = "pastwisko-module--hl--czSeH";
export var lnkContainer = "pastwisko-module--lnk-container--uGsmt";
export var lnkWrapper = "pastwisko-module--lnk-wrapper--v6oEF";
export var logo = "pastwisko-module--logo--jHIeg";
export var streamIco = "pastwisko-module--stream-ico--xL+26";
export var streamList = "pastwisko-module--stream-list--6kTjq";
export var titl = "pastwisko-module--titl--WxQo+";