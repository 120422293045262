import * as React from "react"
import * as styles from './pastwisko.module.scss'
import { StaticImage } from 'gatsby-plugin-image'
import {SiSpotify, SiApplemusic, SiYoutubemusic, SiTidal, SiDeezer} from 'react-icons/si';
import Logo from "../../images/assets/logo_outline.svg"
import { Link } from 'gatsby'
import SEO from "../../components/seo"
import { OutboundLink } from "gatsby-plugin-google-gtag"




const streamPastwisko = () => {

    const streamLinks = {
        spotify: "https://open.spotify.com/album/5DSwI76Le98YvuLMIpSAog",
        appleMusic: "https://music.apple.com/pl/album/pastwisko-ep/1580504482",
        youtubeMusic: "https://music.youtube.com/playlist?list=OLAK5uy_nzRk4_VXUOmPzuI77qgyAP5l_F7WZZ4fw",
        tidal: "https://listen.tidal.com/album/193713884",
        deezer: "https://www.deezer.com/pl/album/250728282"
    }
    
    return (
        <div className={styles.lnkContainer}>
            <SEO title="Pastwisko" bodyClass={styles.bodyCol}/>
            <div className={styles.lnkWrapper}>
                <StaticImage
                    alt="The artwork of Pastwisko, the second EP"
                    src="../../images/pastwisko_artwork.jpg"
                    className={styles.artworkImg}
                /> 

                <h1 className={styles.titl}>POSŁUCHAJ TUTAJ</h1>

                <div className={styles.streamList}>
                    <OutboundLink href={streamLinks.spotify} tabIndex="0" className={styles.streamIco} aria-label="Stream Spotify"><SiSpotify aria-hidden="true" size="2rem"/>
                        <span>SPOTIFY</span></OutboundLink>
                    <OutboundLink href={streamLinks.appleMusic} tabIndex="0" className={styles.streamIco} aria-label="Stream Apple Music"><SiApplemusic aria-hidden="true" size="2rem"/>
                        <span>APPLE MUSIC</span></OutboundLink>
                    <OutboundLink href={streamLinks.youtubeMusic} tabIndex="0" className={styles.streamIco} aria-label="Stream Youtube Music"><SiYoutubemusic aria-hidden="true" size="2rem"/>
                        <span>YOUTUBE MUSIC</span></OutboundLink>
                    <OutboundLink href={streamLinks.tidal} tabIndex="0" className={styles.streamIco} aria-label="Stream Tidal"><SiTidal aria-hidden="true" size="2rem"/>
                        <span>TIDAL</span></OutboundLink>
                    <OutboundLink href={streamLinks.deezer} tabIndex="0" className={styles.streamIco} aria-label="Stream Deezer"><SiDeezer aria-hidden="true" size="2rem"/>
                        <span>DEEZER</span></OutboundLink>
                </div>

                <div className={styles.hl}></div>

                <div className={styles.credits}>
                    <div className={styles.logo} ><Link to="/" aria-label="Ambaras Strona Głowna"><Logo aria-hidden="true" /></Link></div>
                    <p className={styles.copyright}>℗ 2021 AMBARASSED RECORDS</p>
                </div>
            </div>
        </div>
    )
}

export default streamPastwisko
